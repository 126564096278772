/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import {
  CompleteFlightBookingFragmentDoc,
  CompleteFlightBookingRefundFragmentDoc,
  CompleteSeamanFragmentDoc,
  VesselFieldsFragmentDoc,
  ParsedFlightOfferFieldsFragmentDoc,
} from './fragments.generated'
import { CompleteOfferFragmentDoc, CompleteFlightFragmentDoc } from './flight-fragments.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type FetchBookingHistoryQueryVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
}>

export type FetchBookingHistoryQuery = {
  bookingHistory: Array<{
    date: string
    name: string
    departure: string
    arrival: string
    pnr: string
    route: string
    status: Types.FlightBookingStatus
    finalOfferAmount: number
    finalOfferCurrency: string
    refundedAmount: number | null
    refundCurrency: string | null
    travelTeam: string
    travelOfficeConfigurationId: string
  }>
}

export type LoadBookingsQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input']
}>

export type LoadBookingsQuery = {
  bookings: Array<{
    id: string
    active: boolean
    monitored: boolean
    ticketLine: string | null
    comment: string | null
    pnr: string | null
    status: Types.FlightBookingStatus
    amadeusSessionId: string | null
    managedByTravelTeam: boolean
    isAmadeus: boolean
    lastTicketingTime: string | null
    lastTicketingTimeSource: Types.LastTicketingTimeSource
    pendingUpdatedByTravelAgentNotification: boolean
    switchForIdenticalCheaperOfferAutomatically: boolean
    switchForSimilarCheaperOfferAutomatically: boolean
    ticketedAt: string | null
    bucketId: string | null
    autoTicket: boolean
    travelOfficeConfigurationId: string | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    offer: {
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }
    finalOffer: { amount: number; currency: Types.Currency } | null
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
    }
    ticketLineData: { documentNumber: string } | null
    splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
  }>
}

export type SearchBookingsByPnrQueryVariables = Types.Exact<{
  pnrQuery: Types.Scalars['String']['input']
}>

export type SearchBookingsByPnrQuery = {
  bookingsByPnr: Array<{
    __typename: 'FlightBookingSummary'
    id: string
    pnr: string
    status: Types.FlightBookingStatus
    eTicketDownloadUrl: string | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      offers: Array<{
        id: string
        amount: number
        tax: number | null
        passengerType: Types.PassengerType | null
        currency: string
        createdAt: string
        updatedAt: string
        flightId: string
        amadeusSessionId: string | null
        segmentFares: Array<{
          availableSeats: number | null
          fareTypes: Array<Types.FareType>
          fareBasisCode: string
          flightSegmentId: string
          bookingClass: string | null
          baggageDetails: {
            freeBagAllowance: number | null
            freeBagAllowanceType: Types.FreeBagAllowanceType | null
          }
        }>
        rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
        minirules: {
          beforeDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          beforeDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
        } | null
        taxDetails: Array<{
          amount: number
          code: string | null
          isNonRefundable: boolean | null
        }> | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    crewChange: {
      bucketId: string
      vesselName: string
      portName: string | null
      ETA: string
      completed: boolean
      started: boolean | null
    }
  }>
}

export type FlightBookingSummaryFieldsFragment = {
  __typename: 'FlightBookingSummary'
  id: string
  pnr: string
  status: Types.FlightBookingStatus
  eTicketDownloadUrl: string | null
  flight: {
    id: string
    updatedAt: string
    createdAt: string
    flightHash: string
    majorityCarrier: string | null
    travelOfficeConfigurationId: string
    validatingCarrier: string | null
    segments: Array<{
      id: string
      flightNumber: number
      index: number
      departure: {
        timezoneOlson: string | null
        time: string
        name: string | null
        iata: string
        countryAlpha2: string | null
        city: string | null
        date: string
        terminal: string | null
      }
      arrival: {
        timezoneOlson: string | null
        time: string
        name: string | null
        iata: string
        countryAlpha2: string | null
        city: string | null
        date: string
        terminal: string | null
      }
      marketingCarrier: {
        id: string
        createdAt: string
        updatedAt: string
        iata: string
        name: string
        logo: string | null
      } | null
      operatingCarrier: {
        id: string
        createdAt: string
        updatedAt: string
        iata: string
        name: string
        logo: string | null
      } | null
    }>
    offers: Array<{
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }>
    travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
  }
  crewChange: {
    bucketId: string
    vesselName: string
    portName: string | null
    ETA: string
    completed: boolean
    started: boolean | null
  }
}

export type GetBookingCancellationActionQueryVariables = Types.Exact<{
  bookingId: Types.Scalars['UUID']['input']
}>

export type GetBookingCancellationActionQuery = {
  bookingInfo: { cancellationAction: Types.BookingCancellationAction }
}

export type TransferFlightBookingsToDifferentCrewChangeMutationVariables = Types.Exact<{
  params: Types.TransferFlightBookingToDifferentCrewChangeParams
}>

export type TransferFlightBookingsToDifferentCrewChangeMutation = {
  transferFlightBookingToDifferentCrewChange: Array<{
    id: string
    active: boolean
    monitored: boolean
    ticketLine: string | null
    comment: string | null
    pnr: string | null
    status: Types.FlightBookingStatus
    amadeusSessionId: string | null
    managedByTravelTeam: boolean
    isAmadeus: boolean
    lastTicketingTime: string | null
    lastTicketingTimeSource: Types.LastTicketingTimeSource
    pendingUpdatedByTravelAgentNotification: boolean
    switchForIdenticalCheaperOfferAutomatically: boolean
    switchForSimilarCheaperOfferAutomatically: boolean
    ticketedAt: string | null
    bucketId: string | null
    autoTicket: boolean
    travelOfficeConfigurationId: string | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    offer: {
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }
    finalOffer: { amount: number; currency: Types.Currency } | null
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
    }
    ticketLineData: { documentNumber: string } | null
    splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
  }>
}

export type BookFlightsMutationVariables = Types.Exact<{
  bookingIds: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input']
}>

export type BookFlightsMutation = {
  bookings: {
    book: Array<{
      id: string
      active: boolean
      monitored: boolean
      ticketLine: string | null
      comment: string | null
      pnr: string | null
      status: Types.FlightBookingStatus
      amadeusSessionId: string | null
      managedByTravelTeam: boolean
      isAmadeus: boolean
      lastTicketingTime: string | null
      lastTicketingTimeSource: Types.LastTicketingTimeSource
      pendingUpdatedByTravelAgentNotification: boolean
      switchForIdenticalCheaperOfferAutomatically: boolean
      switchForSimilarCheaperOfferAutomatically: boolean
      ticketedAt: string | null
      bucketId: string | null
      autoTicket: boolean
      travelOfficeConfigurationId: string | null
      flight: {
        id: string
        updatedAt: string
        createdAt: string
        flightHash: string
        majorityCarrier: string | null
        travelOfficeConfigurationId: string
        validatingCarrier: string | null
        segments: Array<{
          id: string
          flightNumber: number
          index: number
          departure: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          arrival: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          marketingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
          operatingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
        }>
        travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
      }
      offer: {
        id: string
        amount: number
        tax: number | null
        passengerType: Types.PassengerType | null
        currency: string
        createdAt: string
        updatedAt: string
        flightId: string
        amadeusSessionId: string | null
        segmentFares: Array<{
          availableSeats: number | null
          fareTypes: Array<Types.FareType>
          fareBasisCode: string
          flightSegmentId: string
          bookingClass: string | null
          baggageDetails: {
            freeBagAllowance: number | null
            freeBagAllowanceType: Types.FreeBagAllowanceType | null
          }
        }>
        rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
        minirules: {
          beforeDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          beforeDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
        } | null
        taxDetails: Array<{
          amount: number
          code: string | null
          isNonRefundable: boolean | null
        }> | null
      }
      finalOffer: { amount: number; currency: Types.Currency } | null
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
      }
      ticketLineData: { documentNumber: string } | null
      splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
    }>
  }
}

export type IssueTicketMutationVariables = Types.Exact<{
  bookingId: Types.Scalars['UUID']['input']
}>

export type IssueTicketMutation = {
  booking: {
    issueTicket: {
      id: string
      active: boolean
      monitored: boolean
      ticketLine: string | null
      comment: string | null
      pnr: string | null
      status: Types.FlightBookingStatus
      amadeusSessionId: string | null
      managedByTravelTeam: boolean
      isAmadeus: boolean
      lastTicketingTime: string | null
      lastTicketingTimeSource: Types.LastTicketingTimeSource
      pendingUpdatedByTravelAgentNotification: boolean
      switchForIdenticalCheaperOfferAutomatically: boolean
      switchForSimilarCheaperOfferAutomatically: boolean
      ticketedAt: string | null
      bucketId: string | null
      autoTicket: boolean
      travelOfficeConfigurationId: string | null
      flight: {
        id: string
        updatedAt: string
        createdAt: string
        flightHash: string
        majorityCarrier: string | null
        travelOfficeConfigurationId: string
        validatingCarrier: string | null
        segments: Array<{
          id: string
          flightNumber: number
          index: number
          departure: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          arrival: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          marketingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
          operatingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
        }>
        travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
      }
      offer: {
        id: string
        amount: number
        tax: number | null
        passengerType: Types.PassengerType | null
        currency: string
        createdAt: string
        updatedAt: string
        flightId: string
        amadeusSessionId: string | null
        segmentFares: Array<{
          availableSeats: number | null
          fareTypes: Array<Types.FareType>
          fareBasisCode: string
          flightSegmentId: string
          bookingClass: string | null
          baggageDetails: {
            freeBagAllowance: number | null
            freeBagAllowanceType: Types.FreeBagAllowanceType | null
          }
        }>
        rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
        minirules: {
          beforeDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          beforeDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
        } | null
        taxDetails: Array<{
          amount: number
          code: string | null
          isNonRefundable: boolean | null
        }> | null
      }
      finalOffer: { amount: number; currency: Types.Currency } | null
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
      }
      ticketLineData: { documentNumber: string } | null
      splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
    }
  }
}

export type BookUnselectedFlightMutationVariables = Types.Exact<{
  params: Types.BookUnselectedInput
}>

export type BookUnselectedFlightMutation = {
  bookUnselected: Array<{
    id: string
    active: boolean
    monitored: boolean
    ticketLine: string | null
    comment: string | null
    pnr: string | null
    status: Types.FlightBookingStatus
    amadeusSessionId: string | null
    managedByTravelTeam: boolean
    isAmadeus: boolean
    lastTicketingTime: string | null
    lastTicketingTimeSource: Types.LastTicketingTimeSource
    pendingUpdatedByTravelAgentNotification: boolean
    switchForIdenticalCheaperOfferAutomatically: boolean
    switchForSimilarCheaperOfferAutomatically: boolean
    ticketedAt: string | null
    bucketId: string | null
    autoTicket: boolean
    travelOfficeConfigurationId: string | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    offer: {
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }
    finalOffer: { amount: number; currency: Types.Currency } | null
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
    }
    ticketLineData: { documentNumber: string } | null
    splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
  }>
}

export type UpdateAddedPnrsMutationVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
}>

export type UpdateAddedPnrsMutation = { updateAddedPnrs: void | null }

export type SetBookingMonitoringStatusMutationVariables = Types.Exact<{
  bookingId: Types.Scalars['UUID']['input']
  monitored: Types.Scalars['Boolean']['input']
  switchForIdenticalCheaperOfferAutomatically?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  switchForSimilarCheaperOfferAutomatically?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
}>

export type SetBookingMonitoringStatusMutation = {
  booking: {
    setBookingMonitoringStatus: {
      id: string
      active: boolean
      monitored: boolean
      ticketLine: string | null
      comment: string | null
      pnr: string | null
      status: Types.FlightBookingStatus
      amadeusSessionId: string | null
      managedByTravelTeam: boolean
      isAmadeus: boolean
      lastTicketingTime: string | null
      lastTicketingTimeSource: Types.LastTicketingTimeSource
      pendingUpdatedByTravelAgentNotification: boolean
      switchForIdenticalCheaperOfferAutomatically: boolean
      switchForSimilarCheaperOfferAutomatically: boolean
      ticketedAt: string | null
      bucketId: string | null
      autoTicket: boolean
      travelOfficeConfigurationId: string | null
      flight: {
        id: string
        updatedAt: string
        createdAt: string
        flightHash: string
        majorityCarrier: string | null
        travelOfficeConfigurationId: string
        validatingCarrier: string | null
        segments: Array<{
          id: string
          flightNumber: number
          index: number
          departure: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          arrival: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          marketingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
          operatingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
        }>
        travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
      }
      offer: {
        id: string
        amount: number
        tax: number | null
        passengerType: Types.PassengerType | null
        currency: string
        createdAt: string
        updatedAt: string
        flightId: string
        amadeusSessionId: string | null
        segmentFares: Array<{
          availableSeats: number | null
          fareTypes: Array<Types.FareType>
          fareBasisCode: string
          flightSegmentId: string
          bookingClass: string | null
          baggageDetails: {
            freeBagAllowance: number | null
            freeBagAllowanceType: Types.FreeBagAllowanceType | null
          }
        }>
        rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
        minirules: {
          beforeDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          beforeDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
        } | null
        taxDetails: Array<{
          amount: number
          code: string | null
          isNonRefundable: boolean | null
        }> | null
      }
      finalOffer: { amount: number; currency: Types.Currency } | null
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
      }
      ticketLineData: { documentNumber: string } | null
      splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
    }
  }
}

export type SetBookingsAutoTicketingMutationVariables = Types.Exact<{
  autoTicket: Types.Scalars['Boolean']['input']
  bookingIds: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input']
}>

export type SetBookingsAutoTicketingMutation = {
  bookings: {
    setAutoTicketing: Array<{
      id: string
      active: boolean
      monitored: boolean
      ticketLine: string | null
      comment: string | null
      pnr: string | null
      status: Types.FlightBookingStatus
      amadeusSessionId: string | null
      managedByTravelTeam: boolean
      isAmadeus: boolean
      lastTicketingTime: string | null
      lastTicketingTimeSource: Types.LastTicketingTimeSource
      pendingUpdatedByTravelAgentNotification: boolean
      switchForIdenticalCheaperOfferAutomatically: boolean
      switchForSimilarCheaperOfferAutomatically: boolean
      ticketedAt: string | null
      bucketId: string | null
      autoTicket: boolean
      travelOfficeConfigurationId: string | null
      flight: {
        id: string
        updatedAt: string
        createdAt: string
        flightHash: string
        majorityCarrier: string | null
        travelOfficeConfigurationId: string
        validatingCarrier: string | null
        segments: Array<{
          id: string
          flightNumber: number
          index: number
          departure: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          arrival: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          marketingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
          operatingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
        }>
        travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
      }
      offer: {
        id: string
        amount: number
        tax: number | null
        passengerType: Types.PassengerType | null
        currency: string
        createdAt: string
        updatedAt: string
        flightId: string
        amadeusSessionId: string | null
        segmentFares: Array<{
          availableSeats: number | null
          fareTypes: Array<Types.FareType>
          fareBasisCode: string
          flightSegmentId: string
          bookingClass: string | null
          baggageDetails: {
            freeBagAllowance: number | null
            freeBagAllowanceType: Types.FreeBagAllowanceType | null
          }
        }>
        rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
        minirules: {
          beforeDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          beforeDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
        } | null
        taxDetails: Array<{
          amount: number
          code: string | null
          isNonRefundable: boolean | null
        }> | null
      }
      finalOffer: { amount: number; currency: Types.Currency } | null
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
      }
      ticketLineData: { documentNumber: string } | null
      splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
    }>
  }
}

export type FetchETicketMutationVariables = Types.Exact<{
  bookingId: Types.Scalars['UUID']['input']
}>

export type FetchETicketMutation = { booking: { fetchETicket: { ticket: { id: string } } } }

export type FetchETicketsMutationVariables = Types.Exact<{
  bookingIds: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input']
}>

export type FetchETicketsMutation = {
  bookings: { fetchETickets: Array<{ ticket: { id: string } }> }
}

export type GetCurrentCheaperOfferNotificationsQueryVariables = Types.Exact<{
  bookingId: Types.Scalars['UUID']['input']
}>

export type GetCurrentCheaperOfferNotificationsQuery = {
  bookingInfo: {
    currentCheaperOfferNotifications: Array<{
      id: string
      dismissed: boolean
      createdAt: string
      cheaperOffer: {
        id: string
        active: boolean
        flightOffer: {
          id: string
          amount: number
          tax: number | null
          passengerType: Types.PassengerType | null
          currency: string
          createdAt: string
          updatedAt: string
          flightId: string
          amadeusSessionId: string | null
          flight: {
            id: string
            updatedAt: string
            createdAt: string
            flightHash: string
            majorityCarrier: string | null
            travelOfficeConfigurationId: string
            validatingCarrier: string | null
            segments: Array<{
              id: string
              flightNumber: number
              index: number
              departure: {
                timezoneOlson: string | null
                time: string
                name: string | null
                iata: string
                countryAlpha2: string | null
                city: string | null
                date: string
                terminal: string | null
              }
              arrival: {
                timezoneOlson: string | null
                time: string
                name: string | null
                iata: string
                countryAlpha2: string | null
                city: string | null
                date: string
                terminal: string | null
              }
              marketingCarrier: {
                id: string
                createdAt: string
                updatedAt: string
                iata: string
                name: string
                logo: string | null
              } | null
              operatingCarrier: {
                id: string
                createdAt: string
                updatedAt: string
                iata: string
                name: string
                logo: string | null
              } | null
            }>
            offers: Array<{
              id: string
              amount: number
              tax: number | null
              passengerType: Types.PassengerType | null
              currency: string
              createdAt: string
              updatedAt: string
              flightId: string
              amadeusSessionId: string | null
              segmentFares: Array<{
                availableSeats: number | null
                fareTypes: Array<Types.FareType>
                fareBasisCode: string
                flightSegmentId: string
                bookingClass: string | null
                baggageDetails: {
                  freeBagAllowance: number | null
                  freeBagAllowanceType: Types.FreeBagAllowanceType | null
                }
              }>
              rules: Array<{
                rules: Array<string>
                id: string
                ruleType: Types.FlightOfferRuleType
              }>
              minirules: {
                beforeDeparture: {
                  changeAllowed: boolean | null
                  changeMaxPenaltyAmount: number | null
                  refundAllowed: boolean | null
                  refundMaxPenaltyAmount: number | null
                  revalidationAllowed: boolean | null
                  revalidationMaxPenaltyAmount: number | null
                  isoTicketValidityDate: string | null
                  taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
                } | null
                beforeDepartureNoShow: {
                  changeAllowed: boolean | null
                  changeMaxPenaltyAmount: number | null
                  refundAllowed: boolean | null
                  refundMaxPenaltyAmount: number | null
                  revalidationAllowed: boolean | null
                  revalidationMaxPenaltyAmount: number | null
                  isoTicketValidityDate: string | null
                  taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
                } | null
                afterDeparture: {
                  changeAllowed: boolean | null
                  changeMaxPenaltyAmount: number | null
                  refundAllowed: boolean | null
                  refundMaxPenaltyAmount: number | null
                  revalidationAllowed: boolean | null
                  revalidationMaxPenaltyAmount: number | null
                  isoTicketValidityDate: string | null
                  taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
                } | null
                afterDepartureNoShow: {
                  changeAllowed: boolean | null
                  changeMaxPenaltyAmount: number | null
                  refundAllowed: boolean | null
                  refundMaxPenaltyAmount: number | null
                  revalidationAllowed: boolean | null
                  revalidationMaxPenaltyAmount: number | null
                  isoTicketValidityDate: string | null
                  taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
                } | null
              } | null
              taxDetails: Array<{
                amount: number
                code: string | null
                isNonRefundable: boolean | null
              }> | null
            }>
            travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
          }
          segmentFares: Array<{
            availableSeats: number | null
            fareTypes: Array<Types.FareType>
            fareBasisCode: string
            flightSegmentId: string
            bookingClass: string | null
            baggageDetails: {
              freeBagAllowance: number | null
              freeBagAllowanceType: Types.FreeBagAllowanceType | null
            }
          }>
          rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
          minirules: {
            beforeDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            beforeDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
          } | null
          taxDetails: Array<{
            amount: number
            code: string | null
            isNonRefundable: boolean | null
          }> | null
        }
      }
    }>
  }
}

export type FlightOfferNotificationFieldsFragment = {
  id: string
  active: boolean
  flightOffer: {
    id: string
    amount: number
    tax: number | null
    passengerType: Types.PassengerType | null
    currency: string
    createdAt: string
    updatedAt: string
    flightId: string
    amadeusSessionId: string | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      offers: Array<{
        id: string
        amount: number
        tax: number | null
        passengerType: Types.PassengerType | null
        currency: string
        createdAt: string
        updatedAt: string
        flightId: string
        amadeusSessionId: string | null
        segmentFares: Array<{
          availableSeats: number | null
          fareTypes: Array<Types.FareType>
          fareBasisCode: string
          flightSegmentId: string
          bookingClass: string | null
          baggageDetails: {
            freeBagAllowance: number | null
            freeBagAllowanceType: Types.FreeBagAllowanceType | null
          }
        }>
        rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
        minirules: {
          beforeDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          beforeDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
        } | null
        taxDetails: Array<{
          amount: number
          code: string | null
          isNonRefundable: boolean | null
        }> | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    segmentFares: Array<{
      availableSeats: number | null
      fareTypes: Array<Types.FareType>
      fareBasisCode: string
      flightSegmentId: string
      bookingClass: string | null
      baggageDetails: {
        freeBagAllowance: number | null
        freeBagAllowanceType: Types.FreeBagAllowanceType | null
      }
    }>
    rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
    minirules: {
      beforeDeparture: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      beforeDepartureNoShow: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      afterDeparture: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      afterDepartureNoShow: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
    } | null
    taxDetails: Array<{
      amount: number
      code: string | null
      isNonRefundable: boolean | null
    }> | null
  }
}

export type DismissAllFlightBookingCheaperOffersMutationVariables = Types.Exact<{
  bookingId: Types.Scalars['UUID']['input']
}>

export type DismissAllFlightBookingCheaperOffersMutation = {
  booking: { dismissCheaperOffers: void | null }
}

export type ShouldRepricePnrQueryVariables = Types.Exact<{
  pnr: Types.Scalars['String']['input']
  bucketId: Types.Scalars['String']['input']
}>

export type ShouldRepricePnrQuery = { shouldRepricePnr: boolean }

export type RepricePnrMutationVariables = Types.Exact<{
  pnr: Types.Scalars['String']['input']
  bucketId: Types.Scalars['String']['input']
}>

export type RepricePnrMutation = {
  repricePnr: Array<{
    id: string
    active: boolean
    monitored: boolean
    ticketLine: string | null
    comment: string | null
    pnr: string | null
    status: Types.FlightBookingStatus
    amadeusSessionId: string | null
    managedByTravelTeam: boolean
    isAmadeus: boolean
    lastTicketingTime: string | null
    lastTicketingTimeSource: Types.LastTicketingTimeSource
    pendingUpdatedByTravelAgentNotification: boolean
    switchForIdenticalCheaperOfferAutomatically: boolean
    switchForSimilarCheaperOfferAutomatically: boolean
    ticketedAt: string | null
    bucketId: string | null
    autoTicket: boolean
    travelOfficeConfigurationId: string | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    offer: {
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }
    finalOffer: { amount: number; currency: Types.Currency } | null
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
    }
    ticketLineData: { documentNumber: string } | null
    splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
  }>
}

export type InitiateRefundMutationVariables = Types.Exact<{
  bookingId: Types.Scalars['UUID']['input']
}>

export type InitiateRefundMutation = {
  booking: {
    initRefund: {
      id: string
      status: Types.FlightBookingRefundStatus
      amount: number | null
      currencyCode: string | null
      decimalPlaces: string | null
      flightBooking: {
        id: string
        active: boolean
        monitored: boolean
        ticketLine: string | null
        comment: string | null
        pnr: string | null
        status: Types.FlightBookingStatus
        amadeusSessionId: string | null
        managedByTravelTeam: boolean
        isAmadeus: boolean
        lastTicketingTime: string | null
        lastTicketingTimeSource: Types.LastTicketingTimeSource
        pendingUpdatedByTravelAgentNotification: boolean
        switchForIdenticalCheaperOfferAutomatically: boolean
        switchForSimilarCheaperOfferAutomatically: boolean
        ticketedAt: string | null
        bucketId: string | null
        autoTicket: boolean
        travelOfficeConfigurationId: string | null
        finalOffer: {
          amount: number
          fixedCommission: number
          fixedCommissionCurrency: Types.Currency
          percentageCommission: number
          currency: Types.Currency
        } | null
        flight: {
          id: string
          updatedAt: string
          createdAt: string
          flightHash: string
          majorityCarrier: string | null
          travelOfficeConfigurationId: string
          validatingCarrier: string | null
          segments: Array<{
            id: string
            flightNumber: number
            index: number
            departure: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            arrival: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            marketingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
            operatingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
          }>
          travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
        }
        offer: {
          id: string
          amount: number
          tax: number | null
          passengerType: Types.PassengerType | null
          currency: string
          createdAt: string
          updatedAt: string
          flightId: string
          amadeusSessionId: string | null
          segmentFares: Array<{
            availableSeats: number | null
            fareTypes: Array<Types.FareType>
            fareBasisCode: string
            flightSegmentId: string
            bookingClass: string | null
            baggageDetails: {
              freeBagAllowance: number | null
              freeBagAllowanceType: Types.FreeBagAllowanceType | null
            }
          }>
          rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
          minirules: {
            beforeDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            beforeDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
          } | null
          taxDetails: Array<{
            amount: number
            code: string | null
            isNonRefundable: boolean | null
          }> | null
        }
        seaman: {
          id: string
          firstName: string
          lastName: string
          nationalityIso: string
          rank: number | null
          birthday: string | null
          placeOfBirth: string | null
          firstVaccinationDate: string | null
          firstVaccinationBrand: string | null
          secondVaccinationDate: string | null
          secondVaccinationBrand: string | null
          updatedAt: string
        }
        ticketLineData: { documentNumber: string } | null
        splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
      }
      penalties: Array<{
        id: string
        amount: number
        currencyCode: string | null
        decimalPlaces: string | null
        penaltyType: string | null
        percentOrAmount: Types.PenaltyCalculationType | null
      }>
      taxes: Array<{
        id: string
        amount: number
        currencyCode: string
        decimalPlaces: string | null
        taxIsoCode: string
        category: string
      } | null>
    }
  }
}

export type IgnoreRefundMutationVariables = Types.Exact<{
  refundId: Types.Scalars['UUID']['input']
}>

export type IgnoreRefundMutation = {
  bookingRefund: {
    ignore: {
      id: string
      status: Types.FlightBookingRefundStatus
      amount: number | null
      currencyCode: string | null
      decimalPlaces: string | null
      flightBooking: {
        id: string
        active: boolean
        monitored: boolean
        ticketLine: string | null
        comment: string | null
        pnr: string | null
        status: Types.FlightBookingStatus
        amadeusSessionId: string | null
        managedByTravelTeam: boolean
        isAmadeus: boolean
        lastTicketingTime: string | null
        lastTicketingTimeSource: Types.LastTicketingTimeSource
        pendingUpdatedByTravelAgentNotification: boolean
        switchForIdenticalCheaperOfferAutomatically: boolean
        switchForSimilarCheaperOfferAutomatically: boolean
        ticketedAt: string | null
        bucketId: string | null
        autoTicket: boolean
        travelOfficeConfigurationId: string | null
        finalOffer: {
          amount: number
          fixedCommission: number
          fixedCommissionCurrency: Types.Currency
          percentageCommission: number
          currency: Types.Currency
        } | null
        flight: {
          id: string
          updatedAt: string
          createdAt: string
          flightHash: string
          majorityCarrier: string | null
          travelOfficeConfigurationId: string
          validatingCarrier: string | null
          segments: Array<{
            id: string
            flightNumber: number
            index: number
            departure: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            arrival: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            marketingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
            operatingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
          }>
          travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
        }
        offer: {
          id: string
          amount: number
          tax: number | null
          passengerType: Types.PassengerType | null
          currency: string
          createdAt: string
          updatedAt: string
          flightId: string
          amadeusSessionId: string | null
          segmentFares: Array<{
            availableSeats: number | null
            fareTypes: Array<Types.FareType>
            fareBasisCode: string
            flightSegmentId: string
            bookingClass: string | null
            baggageDetails: {
              freeBagAllowance: number | null
              freeBagAllowanceType: Types.FreeBagAllowanceType | null
            }
          }>
          rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
          minirules: {
            beforeDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            beforeDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
          } | null
          taxDetails: Array<{
            amount: number
            code: string | null
            isNonRefundable: boolean | null
          }> | null
        }
        seaman: {
          id: string
          firstName: string
          lastName: string
          nationalityIso: string
          rank: number | null
          birthday: string | null
          placeOfBirth: string | null
          firstVaccinationDate: string | null
          firstVaccinationBrand: string | null
          secondVaccinationDate: string | null
          secondVaccinationBrand: string | null
          updatedAt: string
        }
        ticketLineData: { documentNumber: string } | null
        splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
      }
      penalties: Array<{
        id: string
        amount: number
        currencyCode: string | null
        decimalPlaces: string | null
        penaltyType: string | null
        percentOrAmount: Types.PenaltyCalculationType | null
      }>
      taxes: Array<{
        id: string
        amount: number
        currencyCode: string
        decimalPlaces: string | null
        taxIsoCode: string
        category: string
      } | null>
    }
  }
}

export type ProcessRefundMutationVariables = Types.Exact<{
  refundId: Types.Scalars['UUID']['input']
}>

export type ProcessRefundMutation = {
  bookingRefund: {
    process: {
      id: string
      status: Types.FlightBookingRefundStatus
      amount: number | null
      currencyCode: string | null
      decimalPlaces: string | null
      flightBooking: {
        id: string
        active: boolean
        monitored: boolean
        ticketLine: string | null
        comment: string | null
        pnr: string | null
        status: Types.FlightBookingStatus
        amadeusSessionId: string | null
        managedByTravelTeam: boolean
        isAmadeus: boolean
        lastTicketingTime: string | null
        lastTicketingTimeSource: Types.LastTicketingTimeSource
        pendingUpdatedByTravelAgentNotification: boolean
        switchForIdenticalCheaperOfferAutomatically: boolean
        switchForSimilarCheaperOfferAutomatically: boolean
        ticketedAt: string | null
        bucketId: string | null
        autoTicket: boolean
        travelOfficeConfigurationId: string | null
        finalOffer: {
          amount: number
          fixedCommission: number
          fixedCommissionCurrency: Types.Currency
          percentageCommission: number
          currency: Types.Currency
        } | null
        flight: {
          id: string
          updatedAt: string
          createdAt: string
          flightHash: string
          majorityCarrier: string | null
          travelOfficeConfigurationId: string
          validatingCarrier: string | null
          segments: Array<{
            id: string
            flightNumber: number
            index: number
            departure: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            arrival: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            marketingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
            operatingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
          }>
          travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
        }
        offer: {
          id: string
          amount: number
          tax: number | null
          passengerType: Types.PassengerType | null
          currency: string
          createdAt: string
          updatedAt: string
          flightId: string
          amadeusSessionId: string | null
          segmentFares: Array<{
            availableSeats: number | null
            fareTypes: Array<Types.FareType>
            fareBasisCode: string
            flightSegmentId: string
            bookingClass: string | null
            baggageDetails: {
              freeBagAllowance: number | null
              freeBagAllowanceType: Types.FreeBagAllowanceType | null
            }
          }>
          rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
          minirules: {
            beforeDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            beforeDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
          } | null
          taxDetails: Array<{
            amount: number
            code: string | null
            isNonRefundable: boolean | null
          }> | null
        }
        seaman: {
          id: string
          firstName: string
          lastName: string
          nationalityIso: string
          rank: number | null
          birthday: string | null
          placeOfBirth: string | null
          firstVaccinationDate: string | null
          firstVaccinationBrand: string | null
          secondVaccinationDate: string | null
          secondVaccinationBrand: string | null
          updatedAt: string
        }
        ticketLineData: { documentNumber: string } | null
        splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
      }
      penalties: Array<{
        id: string
        amount: number
        currencyCode: string | null
        decimalPlaces: string | null
        penaltyType: string | null
        percentOrAmount: Types.PenaltyCalculationType | null
      }>
      taxes: Array<{
        id: string
        amount: number
        currencyCode: string
        decimalPlaces: string | null
        taxIsoCode: string
        category: string
      } | null>
    }
  }
}

export type CancelBookingMutationVariables = Types.Exact<{
  bookingId: Types.Scalars['UUID']['input']
}>

export type CancelBookingMutation = {
  booking: {
    cancelPnrItinerary: {
      id: string
      active: boolean
      monitored: boolean
      ticketLine: string | null
      comment: string | null
      pnr: string | null
      status: Types.FlightBookingStatus
      amadeusSessionId: string | null
      managedByTravelTeam: boolean
      isAmadeus: boolean
      lastTicketingTime: string | null
      lastTicketingTimeSource: Types.LastTicketingTimeSource
      pendingUpdatedByTravelAgentNotification: boolean
      switchForIdenticalCheaperOfferAutomatically: boolean
      switchForSimilarCheaperOfferAutomatically: boolean
      ticketedAt: string | null
      bucketId: string | null
      autoTicket: boolean
      travelOfficeConfigurationId: string | null
      flight: {
        id: string
        updatedAt: string
        createdAt: string
        flightHash: string
        majorityCarrier: string | null
        travelOfficeConfigurationId: string
        validatingCarrier: string | null
        segments: Array<{
          id: string
          flightNumber: number
          index: number
          departure: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          arrival: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          marketingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
          operatingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
        }>
        travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
      }
      offer: {
        id: string
        amount: number
        tax: number | null
        passengerType: Types.PassengerType | null
        currency: string
        createdAt: string
        updatedAt: string
        flightId: string
        amadeusSessionId: string | null
        segmentFares: Array<{
          availableSeats: number | null
          fareTypes: Array<Types.FareType>
          fareBasisCode: string
          flightSegmentId: string
          bookingClass: string | null
          baggageDetails: {
            freeBagAllowance: number | null
            freeBagAllowanceType: Types.FreeBagAllowanceType | null
          }
        }>
        rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
        minirules: {
          beforeDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          beforeDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
        } | null
        taxDetails: Array<{
          amount: number
          code: string | null
          isNonRefundable: boolean | null
        }> | null
      }
      finalOffer: { amount: number; currency: Types.Currency } | null
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
      }
      ticketLineData: { documentNumber: string } | null
      splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
    }
  }
}

export type VoidTicketMutationVariables = Types.Exact<{
  bookingId: Types.Scalars['UUID']['input']
}>

export type VoidTicketMutation = {
  booking: {
    cancelETicket: {
      id: string
      active: boolean
      monitored: boolean
      ticketLine: string | null
      comment: string | null
      pnr: string | null
      status: Types.FlightBookingStatus
      amadeusSessionId: string | null
      managedByTravelTeam: boolean
      isAmadeus: boolean
      lastTicketingTime: string | null
      lastTicketingTimeSource: Types.LastTicketingTimeSource
      pendingUpdatedByTravelAgentNotification: boolean
      switchForIdenticalCheaperOfferAutomatically: boolean
      switchForSimilarCheaperOfferAutomatically: boolean
      ticketedAt: string | null
      bucketId: string | null
      autoTicket: boolean
      travelOfficeConfigurationId: string | null
      flight: {
        id: string
        updatedAt: string
        createdAt: string
        flightHash: string
        majorityCarrier: string | null
        travelOfficeConfigurationId: string
        validatingCarrier: string | null
        segments: Array<{
          id: string
          flightNumber: number
          index: number
          departure: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          arrival: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          marketingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
          operatingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
        }>
        travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
      }
      offer: {
        id: string
        amount: number
        tax: number | null
        passengerType: Types.PassengerType | null
        currency: string
        createdAt: string
        updatedAt: string
        flightId: string
        amadeusSessionId: string | null
        segmentFares: Array<{
          availableSeats: number | null
          fareTypes: Array<Types.FareType>
          fareBasisCode: string
          flightSegmentId: string
          bookingClass: string | null
          baggageDetails: {
            freeBagAllowance: number | null
            freeBagAllowanceType: Types.FreeBagAllowanceType | null
          }
        }>
        rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
        minirules: {
          beforeDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          beforeDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
        } | null
        taxDetails: Array<{
          amount: number
          code: string | null
          isNonRefundable: boolean | null
        }> | null
      }
      finalOffer: { amount: number; currency: Types.Currency } | null
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
      }
      ticketLineData: { documentNumber: string } | null
      splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
    }
  }
}

export type DeactivateMutationVariables = Types.Exact<{
  bookingId: Types.Scalars['UUID']['input']
}>

export type DeactivateMutation = {
  booking: {
    deactivate: {
      id: string
      active: boolean
      monitored: boolean
      ticketLine: string | null
      comment: string | null
      pnr: string | null
      status: Types.FlightBookingStatus
      amadeusSessionId: string | null
      managedByTravelTeam: boolean
      isAmadeus: boolean
      lastTicketingTime: string | null
      lastTicketingTimeSource: Types.LastTicketingTimeSource
      pendingUpdatedByTravelAgentNotification: boolean
      switchForIdenticalCheaperOfferAutomatically: boolean
      switchForSimilarCheaperOfferAutomatically: boolean
      ticketedAt: string | null
      bucketId: string | null
      autoTicket: boolean
      travelOfficeConfigurationId: string | null
      flight: {
        id: string
        updatedAt: string
        createdAt: string
        flightHash: string
        majorityCarrier: string | null
        travelOfficeConfigurationId: string
        validatingCarrier: string | null
        segments: Array<{
          id: string
          flightNumber: number
          index: number
          departure: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          arrival: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          marketingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
          operatingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
        }>
        travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
      }
      offer: {
        id: string
        amount: number
        tax: number | null
        passengerType: Types.PassengerType | null
        currency: string
        createdAt: string
        updatedAt: string
        flightId: string
        amadeusSessionId: string | null
        segmentFares: Array<{
          availableSeats: number | null
          fareTypes: Array<Types.FareType>
          fareBasisCode: string
          flightSegmentId: string
          bookingClass: string | null
          baggageDetails: {
            freeBagAllowance: number | null
            freeBagAllowanceType: Types.FreeBagAllowanceType | null
          }
        }>
        rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
        minirules: {
          beforeDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          beforeDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
        } | null
        taxDetails: Array<{
          amount: number
          code: string | null
          isNonRefundable: boolean | null
        }> | null
      }
      finalOffer: { amount: number; currency: Types.Currency } | null
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
      }
      ticketLineData: { documentNumber: string } | null
      splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
    }
  }
}

export type MarkCheaperOfferAsSwitchedMutationVariables = Types.Exact<{
  cheaperOfferId: Types.Scalars['UUID']['input']
  bookingIdSwitchedTo: Types.Scalars['UUID']['input']
}>

export type MarkCheaperOfferAsSwitchedMutation = {
  markCheaperOfferAsSwitched: { id: string; userSwitchedAt: string | null } | null
}

export type SearchForCheaperOffersMutationVariables = Types.Exact<{
  bookingId: Types.Scalars['UUID']['input']
}>

export type SearchForCheaperOffersMutation = {
  booking: { searchForCheaperOffers: Array<{ id: string }> | null }
}

export type CreateSplitTicketingBookingMutationVariables = Types.Exact<{ [key: string]: never }>

export type CreateSplitTicketingBookingMutation = { createSplitTicketingBooking: { id: string } }

export const FlightBookingSummaryFieldsFragmentDoc = gql`
  fragment FlightBookingSummaryFields on FlightBookingSummary {
    __typename
    id
    pnr
    status
    eTicketDownloadUrl
    flight {
      ...CompleteFlight
    }
    crewChange {
      bucketId
      vesselName
      portName
      ETA
      completed
      started
    }
  }
  ${CompleteFlightFragmentDoc}
`
export const FlightOfferNotificationFieldsFragmentDoc = gql`
  fragment FlightOfferNotificationFields on FlightOfferNotification {
    id
    active
    flightOffer {
      ...CompleteOffer
      flight {
        ...CompleteFlight
      }
    }
  }
  ${CompleteOfferFragmentDoc}
  ${CompleteFlightFragmentDoc}
`
export const FetchBookingHistoryDocument = gql`
  query FetchBookingHistory($bucketId: UUID!) {
    bookingHistory(bucketId: $bucketId) {
      date
      name
      departure
      arrival
      pnr
      route
      status
      finalOfferAmount
      finalOfferCurrency
      refundedAmount
      refundCurrency
      travelTeam
      travelOfficeConfigurationId
    }
  }
`

/**
 * __useFetchBookingHistoryQuery__
 *
 * To run a query within a React component, call `useFetchBookingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBookingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBookingHistoryQuery({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *   },
 * });
 */
export function useFetchBookingHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchBookingHistoryQuery,
    FetchBookingHistoryQueryVariables
  > &
    ({ variables: FetchBookingHistoryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchBookingHistoryQuery, FetchBookingHistoryQueryVariables>(
    FetchBookingHistoryDocument,
    options
  )
}
export function useFetchBookingHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchBookingHistoryQuery,
    FetchBookingHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchBookingHistoryQuery, FetchBookingHistoryQueryVariables>(
    FetchBookingHistoryDocument,
    options
  )
}
export function useFetchBookingHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FetchBookingHistoryQuery,
    FetchBookingHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<FetchBookingHistoryQuery, FetchBookingHistoryQueryVariables>(
    FetchBookingHistoryDocument,
    options
  )
}
export type FetchBookingHistoryQueryHookResult = ReturnType<typeof useFetchBookingHistoryQuery>
export type FetchBookingHistoryLazyQueryHookResult = ReturnType<
  typeof useFetchBookingHistoryLazyQuery
>
export type FetchBookingHistorySuspenseQueryHookResult = ReturnType<
  typeof useFetchBookingHistorySuspenseQuery
>
export type FetchBookingHistoryQueryResult = Apollo.QueryResult<
  FetchBookingHistoryQuery,
  FetchBookingHistoryQueryVariables
>
export const LoadBookingsDocument = gql`
  query LoadBookings($ids: [UUID!]!) {
    bookings(ids: $ids) {
      ...CompleteFlightBooking
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`

/**
 * __useLoadBookingsQuery__
 *
 * To run a query within a React component, call `useLoadBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadBookingsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLoadBookingsQuery(
  baseOptions: Apollo.QueryHookOptions<LoadBookingsQuery, LoadBookingsQueryVariables> &
    ({ variables: LoadBookingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadBookingsQuery, LoadBookingsQueryVariables>(
    LoadBookingsDocument,
    options
  )
}
export function useLoadBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoadBookingsQuery, LoadBookingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadBookingsQuery, LoadBookingsQueryVariables>(
    LoadBookingsDocument,
    options
  )
}
export function useLoadBookingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LoadBookingsQuery, LoadBookingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadBookingsQuery, LoadBookingsQueryVariables>(
    LoadBookingsDocument,
    options
  )
}
export type LoadBookingsQueryHookResult = ReturnType<typeof useLoadBookingsQuery>
export type LoadBookingsLazyQueryHookResult = ReturnType<typeof useLoadBookingsLazyQuery>
export type LoadBookingsSuspenseQueryHookResult = ReturnType<typeof useLoadBookingsSuspenseQuery>
export type LoadBookingsQueryResult = Apollo.QueryResult<
  LoadBookingsQuery,
  LoadBookingsQueryVariables
>
export const SearchBookingsByPnrDocument = gql`
  query SearchBookingsByPnr($pnrQuery: String!) {
    bookingsByPnr(pnrQuery: $pnrQuery) {
      ...FlightBookingSummaryFields
    }
  }
  ${FlightBookingSummaryFieldsFragmentDoc}
`

/**
 * __useSearchBookingsByPnrQuery__
 *
 * To run a query within a React component, call `useSearchBookingsByPnrQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBookingsByPnrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBookingsByPnrQuery({
 *   variables: {
 *      pnrQuery: // value for 'pnrQuery'
 *   },
 * });
 */
export function useSearchBookingsByPnrQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchBookingsByPnrQuery,
    SearchBookingsByPnrQueryVariables
  > &
    ({ variables: SearchBookingsByPnrQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchBookingsByPnrQuery, SearchBookingsByPnrQueryVariables>(
    SearchBookingsByPnrDocument,
    options
  )
}
export function useSearchBookingsByPnrLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchBookingsByPnrQuery,
    SearchBookingsByPnrQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchBookingsByPnrQuery, SearchBookingsByPnrQueryVariables>(
    SearchBookingsByPnrDocument,
    options
  )
}
export function useSearchBookingsByPnrSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SearchBookingsByPnrQuery,
    SearchBookingsByPnrQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SearchBookingsByPnrQuery, SearchBookingsByPnrQueryVariables>(
    SearchBookingsByPnrDocument,
    options
  )
}
export type SearchBookingsByPnrQueryHookResult = ReturnType<typeof useSearchBookingsByPnrQuery>
export type SearchBookingsByPnrLazyQueryHookResult = ReturnType<
  typeof useSearchBookingsByPnrLazyQuery
>
export type SearchBookingsByPnrSuspenseQueryHookResult = ReturnType<
  typeof useSearchBookingsByPnrSuspenseQuery
>
export type SearchBookingsByPnrQueryResult = Apollo.QueryResult<
  SearchBookingsByPnrQuery,
  SearchBookingsByPnrQueryVariables
>
export const GetBookingCancellationActionDocument = gql`
  query GetBookingCancellationAction($bookingId: UUID!) {
    bookingInfo(bookingId: $bookingId) {
      cancellationAction
    }
  }
`

/**
 * __useGetBookingCancellationActionQuery__
 *
 * To run a query within a React component, call `useGetBookingCancellationActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingCancellationActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingCancellationActionQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetBookingCancellationActionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBookingCancellationActionQuery,
    GetBookingCancellationActionQueryVariables
  > &
    ({ variables: GetBookingCancellationActionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetBookingCancellationActionQuery,
    GetBookingCancellationActionQueryVariables
  >(GetBookingCancellationActionDocument, options)
}
export function useGetBookingCancellationActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBookingCancellationActionQuery,
    GetBookingCancellationActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBookingCancellationActionQuery,
    GetBookingCancellationActionQueryVariables
  >(GetBookingCancellationActionDocument, options)
}
export function useGetBookingCancellationActionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetBookingCancellationActionQuery,
    GetBookingCancellationActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetBookingCancellationActionQuery,
    GetBookingCancellationActionQueryVariables
  >(GetBookingCancellationActionDocument, options)
}
export type GetBookingCancellationActionQueryHookResult = ReturnType<
  typeof useGetBookingCancellationActionQuery
>
export type GetBookingCancellationActionLazyQueryHookResult = ReturnType<
  typeof useGetBookingCancellationActionLazyQuery
>
export type GetBookingCancellationActionSuspenseQueryHookResult = ReturnType<
  typeof useGetBookingCancellationActionSuspenseQuery
>
export type GetBookingCancellationActionQueryResult = Apollo.QueryResult<
  GetBookingCancellationActionQuery,
  GetBookingCancellationActionQueryVariables
>
export const TransferFlightBookingsToDifferentCrewChangeDocument = gql`
  mutation TransferFlightBookingsToDifferentCrewChange(
    $params: TransferFlightBookingToDifferentCrewChangeParams!
  ) {
    transferFlightBookingToDifferentCrewChange(params: $params) {
      ...CompleteFlightBooking
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type TransferFlightBookingsToDifferentCrewChangeMutationFn = Apollo.MutationFunction<
  TransferFlightBookingsToDifferentCrewChangeMutation,
  TransferFlightBookingsToDifferentCrewChangeMutationVariables
>

/**
 * __useTransferFlightBookingsToDifferentCrewChangeMutation__
 *
 * To run a mutation, you first call `useTransferFlightBookingsToDifferentCrewChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferFlightBookingsToDifferentCrewChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferFlightBookingsToDifferentCrewChangeMutation, { data, loading, error }] = useTransferFlightBookingsToDifferentCrewChangeMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useTransferFlightBookingsToDifferentCrewChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransferFlightBookingsToDifferentCrewChangeMutation,
    TransferFlightBookingsToDifferentCrewChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TransferFlightBookingsToDifferentCrewChangeMutation,
    TransferFlightBookingsToDifferentCrewChangeMutationVariables
  >(TransferFlightBookingsToDifferentCrewChangeDocument, options)
}
export type TransferFlightBookingsToDifferentCrewChangeMutationHookResult = ReturnType<
  typeof useTransferFlightBookingsToDifferentCrewChangeMutation
>
export type TransferFlightBookingsToDifferentCrewChangeMutationResult =
  Apollo.MutationResult<TransferFlightBookingsToDifferentCrewChangeMutation>
export type TransferFlightBookingsToDifferentCrewChangeMutationOptions = Apollo.BaseMutationOptions<
  TransferFlightBookingsToDifferentCrewChangeMutation,
  TransferFlightBookingsToDifferentCrewChangeMutationVariables
>
export const BookFlightsDocument = gql`
  mutation BookFlights($bookingIds: [UUID!]!) {
    bookings(ids: $bookingIds) {
      book {
        ...CompleteFlightBooking
      }
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type BookFlightsMutationFn = Apollo.MutationFunction<
  BookFlightsMutation,
  BookFlightsMutationVariables
>

/**
 * __useBookFlightsMutation__
 *
 * To run a mutation, you first call `useBookFlightsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookFlightsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookFlightsMutation, { data, loading, error }] = useBookFlightsMutation({
 *   variables: {
 *      bookingIds: // value for 'bookingIds'
 *   },
 * });
 */
export function useBookFlightsMutation(
  baseOptions?: Apollo.MutationHookOptions<BookFlightsMutation, BookFlightsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BookFlightsMutation, BookFlightsMutationVariables>(
    BookFlightsDocument,
    options
  )
}
export type BookFlightsMutationHookResult = ReturnType<typeof useBookFlightsMutation>
export type BookFlightsMutationResult = Apollo.MutationResult<BookFlightsMutation>
export type BookFlightsMutationOptions = Apollo.BaseMutationOptions<
  BookFlightsMutation,
  BookFlightsMutationVariables
>
export const IssueTicketDocument = gql`
  mutation IssueTicket($bookingId: UUID!) {
    booking(id: $bookingId) {
      issueTicket {
        ...CompleteFlightBooking
      }
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type IssueTicketMutationFn = Apollo.MutationFunction<
  IssueTicketMutation,
  IssueTicketMutationVariables
>

/**
 * __useIssueTicketMutation__
 *
 * To run a mutation, you first call `useIssueTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueTicketMutation, { data, loading, error }] = useIssueTicketMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useIssueTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<IssueTicketMutation, IssueTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IssueTicketMutation, IssueTicketMutationVariables>(
    IssueTicketDocument,
    options
  )
}
export type IssueTicketMutationHookResult = ReturnType<typeof useIssueTicketMutation>
export type IssueTicketMutationResult = Apollo.MutationResult<IssueTicketMutation>
export type IssueTicketMutationOptions = Apollo.BaseMutationOptions<
  IssueTicketMutation,
  IssueTicketMutationVariables
>
export const BookUnselectedFlightDocument = gql`
  mutation BookUnselectedFlight($params: BookUnselectedInput!) {
    bookUnselected(params: $params) {
      ...CompleteFlightBooking
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type BookUnselectedFlightMutationFn = Apollo.MutationFunction<
  BookUnselectedFlightMutation,
  BookUnselectedFlightMutationVariables
>

/**
 * __useBookUnselectedFlightMutation__
 *
 * To run a mutation, you first call `useBookUnselectedFlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookUnselectedFlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookUnselectedFlightMutation, { data, loading, error }] = useBookUnselectedFlightMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useBookUnselectedFlightMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookUnselectedFlightMutation,
    BookUnselectedFlightMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BookUnselectedFlightMutation, BookUnselectedFlightMutationVariables>(
    BookUnselectedFlightDocument,
    options
  )
}
export type BookUnselectedFlightMutationHookResult = ReturnType<
  typeof useBookUnselectedFlightMutation
>
export type BookUnselectedFlightMutationResult = Apollo.MutationResult<BookUnselectedFlightMutation>
export type BookUnselectedFlightMutationOptions = Apollo.BaseMutationOptions<
  BookUnselectedFlightMutation,
  BookUnselectedFlightMutationVariables
>
export const UpdateAddedPnrsDocument = gql`
  mutation UpdateAddedPnrs($bucketId: UUID!) {
    updateAddedPnrs(bucketId: $bucketId)
  }
`
export type UpdateAddedPnrsMutationFn = Apollo.MutationFunction<
  UpdateAddedPnrsMutation,
  UpdateAddedPnrsMutationVariables
>

/**
 * __useUpdateAddedPnrsMutation__
 *
 * To run a mutation, you first call `useUpdateAddedPnrsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddedPnrsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddedPnrsMutation, { data, loading, error }] = useUpdateAddedPnrsMutation({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *   },
 * });
 */
export function useUpdateAddedPnrsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAddedPnrsMutation,
    UpdateAddedPnrsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAddedPnrsMutation, UpdateAddedPnrsMutationVariables>(
    UpdateAddedPnrsDocument,
    options
  )
}
export type UpdateAddedPnrsMutationHookResult = ReturnType<typeof useUpdateAddedPnrsMutation>
export type UpdateAddedPnrsMutationResult = Apollo.MutationResult<UpdateAddedPnrsMutation>
export type UpdateAddedPnrsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAddedPnrsMutation,
  UpdateAddedPnrsMutationVariables
>
export const SetBookingMonitoringStatusDocument = gql`
  mutation SetBookingMonitoringStatus(
    $bookingId: UUID!
    $monitored: Boolean!
    $switchForIdenticalCheaperOfferAutomatically: Boolean
    $switchForSimilarCheaperOfferAutomatically: Boolean
  ) {
    booking(id: $bookingId) {
      setBookingMonitoringStatus(
        monitored: $monitored
        switchForIdenticalCheaperOfferAutomatically: $switchForIdenticalCheaperOfferAutomatically
        switchForSimilarCheaperOfferAutomatically: $switchForSimilarCheaperOfferAutomatically
      ) {
        ...CompleteFlightBooking
      }
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type SetBookingMonitoringStatusMutationFn = Apollo.MutationFunction<
  SetBookingMonitoringStatusMutation,
  SetBookingMonitoringStatusMutationVariables
>

/**
 * __useSetBookingMonitoringStatusMutation__
 *
 * To run a mutation, you first call `useSetBookingMonitoringStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBookingMonitoringStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBookingMonitoringStatusMutation, { data, loading, error }] = useSetBookingMonitoringStatusMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      monitored: // value for 'monitored'
 *      switchForIdenticalCheaperOfferAutomatically: // value for 'switchForIdenticalCheaperOfferAutomatically'
 *      switchForSimilarCheaperOfferAutomatically: // value for 'switchForSimilarCheaperOfferAutomatically'
 *   },
 * });
 */
export function useSetBookingMonitoringStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetBookingMonitoringStatusMutation,
    SetBookingMonitoringStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetBookingMonitoringStatusMutation,
    SetBookingMonitoringStatusMutationVariables
  >(SetBookingMonitoringStatusDocument, options)
}
export type SetBookingMonitoringStatusMutationHookResult = ReturnType<
  typeof useSetBookingMonitoringStatusMutation
>
export type SetBookingMonitoringStatusMutationResult =
  Apollo.MutationResult<SetBookingMonitoringStatusMutation>
export type SetBookingMonitoringStatusMutationOptions = Apollo.BaseMutationOptions<
  SetBookingMonitoringStatusMutation,
  SetBookingMonitoringStatusMutationVariables
>
export const SetBookingsAutoTicketingDocument = gql`
  mutation SetBookingsAutoTicketing($autoTicket: Boolean!, $bookingIds: [UUID!]!) {
    bookings(ids: $bookingIds) {
      setAutoTicketing(autoTicket: $autoTicket) {
        ...CompleteFlightBooking
      }
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type SetBookingsAutoTicketingMutationFn = Apollo.MutationFunction<
  SetBookingsAutoTicketingMutation,
  SetBookingsAutoTicketingMutationVariables
>

/**
 * __useSetBookingsAutoTicketingMutation__
 *
 * To run a mutation, you first call `useSetBookingsAutoTicketingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBookingsAutoTicketingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBookingsAutoTicketingMutation, { data, loading, error }] = useSetBookingsAutoTicketingMutation({
 *   variables: {
 *      autoTicket: // value for 'autoTicket'
 *      bookingIds: // value for 'bookingIds'
 *   },
 * });
 */
export function useSetBookingsAutoTicketingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetBookingsAutoTicketingMutation,
    SetBookingsAutoTicketingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetBookingsAutoTicketingMutation,
    SetBookingsAutoTicketingMutationVariables
  >(SetBookingsAutoTicketingDocument, options)
}
export type SetBookingsAutoTicketingMutationHookResult = ReturnType<
  typeof useSetBookingsAutoTicketingMutation
>
export type SetBookingsAutoTicketingMutationResult =
  Apollo.MutationResult<SetBookingsAutoTicketingMutation>
export type SetBookingsAutoTicketingMutationOptions = Apollo.BaseMutationOptions<
  SetBookingsAutoTicketingMutation,
  SetBookingsAutoTicketingMutationVariables
>
export const FetchETicketDocument = gql`
  mutation FetchETicket($bookingId: UUID!) {
    booking(id: $bookingId) {
      fetchETicket {
        ticket {
          id
        }
      }
    }
  }
`
export type FetchETicketMutationFn = Apollo.MutationFunction<
  FetchETicketMutation,
  FetchETicketMutationVariables
>

/**
 * __useFetchETicketMutation__
 *
 * To run a mutation, you first call `useFetchETicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchETicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchETicketMutation, { data, loading, error }] = useFetchETicketMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useFetchETicketMutation(
  baseOptions?: Apollo.MutationHookOptions<FetchETicketMutation, FetchETicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<FetchETicketMutation, FetchETicketMutationVariables>(
    FetchETicketDocument,
    options
  )
}
export type FetchETicketMutationHookResult = ReturnType<typeof useFetchETicketMutation>
export type FetchETicketMutationResult = Apollo.MutationResult<FetchETicketMutation>
export type FetchETicketMutationOptions = Apollo.BaseMutationOptions<
  FetchETicketMutation,
  FetchETicketMutationVariables
>
export const FetchETicketsDocument = gql`
  mutation FetchETickets($bookingIds: [UUID!]!) {
    bookings(ids: $bookingIds) {
      fetchETickets {
        ticket {
          id
        }
      }
    }
  }
`
export type FetchETicketsMutationFn = Apollo.MutationFunction<
  FetchETicketsMutation,
  FetchETicketsMutationVariables
>

/**
 * __useFetchETicketsMutation__
 *
 * To run a mutation, you first call `useFetchETicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchETicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchETicketsMutation, { data, loading, error }] = useFetchETicketsMutation({
 *   variables: {
 *      bookingIds: // value for 'bookingIds'
 *   },
 * });
 */
export function useFetchETicketsMutation(
  baseOptions?: Apollo.MutationHookOptions<FetchETicketsMutation, FetchETicketsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<FetchETicketsMutation, FetchETicketsMutationVariables>(
    FetchETicketsDocument,
    options
  )
}
export type FetchETicketsMutationHookResult = ReturnType<typeof useFetchETicketsMutation>
export type FetchETicketsMutationResult = Apollo.MutationResult<FetchETicketsMutation>
export type FetchETicketsMutationOptions = Apollo.BaseMutationOptions<
  FetchETicketsMutation,
  FetchETicketsMutationVariables
>
export const GetCurrentCheaperOfferNotificationsDocument = gql`
  query GetCurrentCheaperOfferNotifications($bookingId: UUID!) {
    bookingInfo(bookingId: $bookingId) {
      currentCheaperOfferNotifications {
        id
        dismissed
        createdAt
        cheaperOffer {
          ...FlightOfferNotificationFields
        }
      }
    }
  }
  ${FlightOfferNotificationFieldsFragmentDoc}
`

/**
 * __useGetCurrentCheaperOfferNotificationsQuery__
 *
 * To run a query within a React component, call `useGetCurrentCheaperOfferNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCheaperOfferNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCheaperOfferNotificationsQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetCurrentCheaperOfferNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentCheaperOfferNotificationsQuery,
    GetCurrentCheaperOfferNotificationsQueryVariables
  > &
    (
      | { variables: GetCurrentCheaperOfferNotificationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCurrentCheaperOfferNotificationsQuery,
    GetCurrentCheaperOfferNotificationsQueryVariables
  >(GetCurrentCheaperOfferNotificationsDocument, options)
}
export function useGetCurrentCheaperOfferNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCheaperOfferNotificationsQuery,
    GetCurrentCheaperOfferNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCurrentCheaperOfferNotificationsQuery,
    GetCurrentCheaperOfferNotificationsQueryVariables
  >(GetCurrentCheaperOfferNotificationsDocument, options)
}
export function useGetCurrentCheaperOfferNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCurrentCheaperOfferNotificationsQuery,
    GetCurrentCheaperOfferNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetCurrentCheaperOfferNotificationsQuery,
    GetCurrentCheaperOfferNotificationsQueryVariables
  >(GetCurrentCheaperOfferNotificationsDocument, options)
}
export type GetCurrentCheaperOfferNotificationsQueryHookResult = ReturnType<
  typeof useGetCurrentCheaperOfferNotificationsQuery
>
export type GetCurrentCheaperOfferNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCheaperOfferNotificationsLazyQuery
>
export type GetCurrentCheaperOfferNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentCheaperOfferNotificationsSuspenseQuery
>
export type GetCurrentCheaperOfferNotificationsQueryResult = Apollo.QueryResult<
  GetCurrentCheaperOfferNotificationsQuery,
  GetCurrentCheaperOfferNotificationsQueryVariables
>
export const DismissAllFlightBookingCheaperOffersDocument = gql`
  mutation DismissAllFlightBookingCheaperOffers($bookingId: UUID!) {
    booking(id: $bookingId) {
      dismissCheaperOffers
    }
  }
`
export type DismissAllFlightBookingCheaperOffersMutationFn = Apollo.MutationFunction<
  DismissAllFlightBookingCheaperOffersMutation,
  DismissAllFlightBookingCheaperOffersMutationVariables
>

/**
 * __useDismissAllFlightBookingCheaperOffersMutation__
 *
 * To run a mutation, you first call `useDismissAllFlightBookingCheaperOffersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissAllFlightBookingCheaperOffersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissAllFlightBookingCheaperOffersMutation, { data, loading, error }] = useDismissAllFlightBookingCheaperOffersMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useDismissAllFlightBookingCheaperOffersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissAllFlightBookingCheaperOffersMutation,
    DismissAllFlightBookingCheaperOffersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DismissAllFlightBookingCheaperOffersMutation,
    DismissAllFlightBookingCheaperOffersMutationVariables
  >(DismissAllFlightBookingCheaperOffersDocument, options)
}
export type DismissAllFlightBookingCheaperOffersMutationHookResult = ReturnType<
  typeof useDismissAllFlightBookingCheaperOffersMutation
>
export type DismissAllFlightBookingCheaperOffersMutationResult =
  Apollo.MutationResult<DismissAllFlightBookingCheaperOffersMutation>
export type DismissAllFlightBookingCheaperOffersMutationOptions = Apollo.BaseMutationOptions<
  DismissAllFlightBookingCheaperOffersMutation,
  DismissAllFlightBookingCheaperOffersMutationVariables
>
export const ShouldRepricePnrDocument = gql`
  query ShouldRepricePnr($pnr: String!, $bucketId: String!) {
    shouldRepricePnr(pnr: $pnr, bucketId: $bucketId)
  }
`

/**
 * __useShouldRepricePnrQuery__
 *
 * To run a query within a React component, call `useShouldRepricePnrQuery` and pass it any options that fit your needs.
 * When your component renders, `useShouldRepricePnrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShouldRepricePnrQuery({
 *   variables: {
 *      pnr: // value for 'pnr'
 *      bucketId: // value for 'bucketId'
 *   },
 * });
 */
export function useShouldRepricePnrQuery(
  baseOptions: Apollo.QueryHookOptions<ShouldRepricePnrQuery, ShouldRepricePnrQueryVariables> &
    ({ variables: ShouldRepricePnrQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ShouldRepricePnrQuery, ShouldRepricePnrQueryVariables>(
    ShouldRepricePnrDocument,
    options
  )
}
export function useShouldRepricePnrLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShouldRepricePnrQuery, ShouldRepricePnrQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ShouldRepricePnrQuery, ShouldRepricePnrQueryVariables>(
    ShouldRepricePnrDocument,
    options
  )
}
export function useShouldRepricePnrSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ShouldRepricePnrQuery,
    ShouldRepricePnrQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ShouldRepricePnrQuery, ShouldRepricePnrQueryVariables>(
    ShouldRepricePnrDocument,
    options
  )
}
export type ShouldRepricePnrQueryHookResult = ReturnType<typeof useShouldRepricePnrQuery>
export type ShouldRepricePnrLazyQueryHookResult = ReturnType<typeof useShouldRepricePnrLazyQuery>
export type ShouldRepricePnrSuspenseQueryHookResult = ReturnType<
  typeof useShouldRepricePnrSuspenseQuery
>
export type ShouldRepricePnrQueryResult = Apollo.QueryResult<
  ShouldRepricePnrQuery,
  ShouldRepricePnrQueryVariables
>
export const RepricePnrDocument = gql`
  mutation RepricePnr($pnr: String!, $bucketId: String!) {
    repricePnr(pnr: $pnr, bucketId: $bucketId) {
      ...CompleteFlightBooking
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type RepricePnrMutationFn = Apollo.MutationFunction<
  RepricePnrMutation,
  RepricePnrMutationVariables
>

/**
 * __useRepricePnrMutation__
 *
 * To run a mutation, you first call `useRepricePnrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepricePnrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [repricePnrMutation, { data, loading, error }] = useRepricePnrMutation({
 *   variables: {
 *      pnr: // value for 'pnr'
 *      bucketId: // value for 'bucketId'
 *   },
 * });
 */
export function useRepricePnrMutation(
  baseOptions?: Apollo.MutationHookOptions<RepricePnrMutation, RepricePnrMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RepricePnrMutation, RepricePnrMutationVariables>(
    RepricePnrDocument,
    options
  )
}
export type RepricePnrMutationHookResult = ReturnType<typeof useRepricePnrMutation>
export type RepricePnrMutationResult = Apollo.MutationResult<RepricePnrMutation>
export type RepricePnrMutationOptions = Apollo.BaseMutationOptions<
  RepricePnrMutation,
  RepricePnrMutationVariables
>
export const InitiateRefundDocument = gql`
  mutation InitiateRefund($bookingId: UUID!) {
    booking(id: $bookingId) {
      initRefund {
        ...CompleteFlightBookingRefund
      }
    }
  }
  ${CompleteFlightBookingRefundFragmentDoc}
`
export type InitiateRefundMutationFn = Apollo.MutationFunction<
  InitiateRefundMutation,
  InitiateRefundMutationVariables
>

/**
 * __useInitiateRefundMutation__
 *
 * To run a mutation, you first call `useInitiateRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateRefundMutation, { data, loading, error }] = useInitiateRefundMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useInitiateRefundMutation(
  baseOptions?: Apollo.MutationHookOptions<InitiateRefundMutation, InitiateRefundMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InitiateRefundMutation, InitiateRefundMutationVariables>(
    InitiateRefundDocument,
    options
  )
}
export type InitiateRefundMutationHookResult = ReturnType<typeof useInitiateRefundMutation>
export type InitiateRefundMutationResult = Apollo.MutationResult<InitiateRefundMutation>
export type InitiateRefundMutationOptions = Apollo.BaseMutationOptions<
  InitiateRefundMutation,
  InitiateRefundMutationVariables
>
export const IgnoreRefundDocument = gql`
  mutation IgnoreRefund($refundId: UUID!) {
    bookingRefund(id: $refundId) {
      ignore {
        ...CompleteFlightBookingRefund
      }
    }
  }
  ${CompleteFlightBookingRefundFragmentDoc}
`
export type IgnoreRefundMutationFn = Apollo.MutationFunction<
  IgnoreRefundMutation,
  IgnoreRefundMutationVariables
>

/**
 * __useIgnoreRefundMutation__
 *
 * To run a mutation, you first call `useIgnoreRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnoreRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignoreRefundMutation, { data, loading, error }] = useIgnoreRefundMutation({
 *   variables: {
 *      refundId: // value for 'refundId'
 *   },
 * });
 */
export function useIgnoreRefundMutation(
  baseOptions?: Apollo.MutationHookOptions<IgnoreRefundMutation, IgnoreRefundMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IgnoreRefundMutation, IgnoreRefundMutationVariables>(
    IgnoreRefundDocument,
    options
  )
}
export type IgnoreRefundMutationHookResult = ReturnType<typeof useIgnoreRefundMutation>
export type IgnoreRefundMutationResult = Apollo.MutationResult<IgnoreRefundMutation>
export type IgnoreRefundMutationOptions = Apollo.BaseMutationOptions<
  IgnoreRefundMutation,
  IgnoreRefundMutationVariables
>
export const ProcessRefundDocument = gql`
  mutation ProcessRefund($refundId: UUID!) {
    bookingRefund(id: $refundId) {
      process {
        ...CompleteFlightBookingRefund
      }
    }
  }
  ${CompleteFlightBookingRefundFragmentDoc}
`
export type ProcessRefundMutationFn = Apollo.MutationFunction<
  ProcessRefundMutation,
  ProcessRefundMutationVariables
>

/**
 * __useProcessRefundMutation__
 *
 * To run a mutation, you first call `useProcessRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processRefundMutation, { data, loading, error }] = useProcessRefundMutation({
 *   variables: {
 *      refundId: // value for 'refundId'
 *   },
 * });
 */
export function useProcessRefundMutation(
  baseOptions?: Apollo.MutationHookOptions<ProcessRefundMutation, ProcessRefundMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ProcessRefundMutation, ProcessRefundMutationVariables>(
    ProcessRefundDocument,
    options
  )
}
export type ProcessRefundMutationHookResult = ReturnType<typeof useProcessRefundMutation>
export type ProcessRefundMutationResult = Apollo.MutationResult<ProcessRefundMutation>
export type ProcessRefundMutationOptions = Apollo.BaseMutationOptions<
  ProcessRefundMutation,
  ProcessRefundMutationVariables
>
export const CancelBookingDocument = gql`
  mutation CancelBooking($bookingId: UUID!) {
    booking(id: $bookingId) {
      cancelPnrItinerary {
        ...CompleteFlightBooking
      }
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type CancelBookingMutationFn = Apollo.MutationFunction<
  CancelBookingMutation,
  CancelBookingMutationVariables
>

/**
 * __useCancelBookingMutation__
 *
 * To run a mutation, you first call `useCancelBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBookingMutation, { data, loading, error }] = useCancelBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useCancelBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelBookingMutation, CancelBookingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelBookingMutation, CancelBookingMutationVariables>(
    CancelBookingDocument,
    options
  )
}
export type CancelBookingMutationHookResult = ReturnType<typeof useCancelBookingMutation>
export type CancelBookingMutationResult = Apollo.MutationResult<CancelBookingMutation>
export type CancelBookingMutationOptions = Apollo.BaseMutationOptions<
  CancelBookingMutation,
  CancelBookingMutationVariables
>
export const VoidTicketDocument = gql`
  mutation VoidTicket($bookingId: UUID!) {
    booking(id: $bookingId) {
      cancelETicket {
        ...CompleteFlightBooking
      }
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type VoidTicketMutationFn = Apollo.MutationFunction<
  VoidTicketMutation,
  VoidTicketMutationVariables
>

/**
 * __useVoidTicketMutation__
 *
 * To run a mutation, you first call `useVoidTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidTicketMutation, { data, loading, error }] = useVoidTicketMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useVoidTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<VoidTicketMutation, VoidTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VoidTicketMutation, VoidTicketMutationVariables>(
    VoidTicketDocument,
    options
  )
}
export type VoidTicketMutationHookResult = ReturnType<typeof useVoidTicketMutation>
export type VoidTicketMutationResult = Apollo.MutationResult<VoidTicketMutation>
export type VoidTicketMutationOptions = Apollo.BaseMutationOptions<
  VoidTicketMutation,
  VoidTicketMutationVariables
>
export const DeactivateDocument = gql`
  mutation Deactivate($bookingId: UUID!) {
    booking(id: $bookingId) {
      deactivate {
        ...CompleteFlightBooking
      }
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type DeactivateMutationFn = Apollo.MutationFunction<
  DeactivateMutation,
  DeactivateMutationVariables
>

/**
 * __useDeactivateMutation__
 *
 * To run a mutation, you first call `useDeactivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateMutation, { data, loading, error }] = useDeactivateMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useDeactivateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateMutation, DeactivateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeactivateMutation, DeactivateMutationVariables>(
    DeactivateDocument,
    options
  )
}
export type DeactivateMutationHookResult = ReturnType<typeof useDeactivateMutation>
export type DeactivateMutationResult = Apollo.MutationResult<DeactivateMutation>
export type DeactivateMutationOptions = Apollo.BaseMutationOptions<
  DeactivateMutation,
  DeactivateMutationVariables
>
export const MarkCheaperOfferAsSwitchedDocument = gql`
  mutation markCheaperOfferAsSwitched($cheaperOfferId: UUID!, $bookingIdSwitchedTo: UUID!) {
    markCheaperOfferAsSwitched(
      cheaperOfferId: $cheaperOfferId
      bookingIdSwitchedTo: $bookingIdSwitchedTo
    ) {
      id
      userSwitchedAt
    }
  }
`
export type MarkCheaperOfferAsSwitchedMutationFn = Apollo.MutationFunction<
  MarkCheaperOfferAsSwitchedMutation,
  MarkCheaperOfferAsSwitchedMutationVariables
>

/**
 * __useMarkCheaperOfferAsSwitchedMutation__
 *
 * To run a mutation, you first call `useMarkCheaperOfferAsSwitchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCheaperOfferAsSwitchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCheaperOfferAsSwitchedMutation, { data, loading, error }] = useMarkCheaperOfferAsSwitchedMutation({
 *   variables: {
 *      cheaperOfferId: // value for 'cheaperOfferId'
 *      bookingIdSwitchedTo: // value for 'bookingIdSwitchedTo'
 *   },
 * });
 */
export function useMarkCheaperOfferAsSwitchedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkCheaperOfferAsSwitchedMutation,
    MarkCheaperOfferAsSwitchedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkCheaperOfferAsSwitchedMutation,
    MarkCheaperOfferAsSwitchedMutationVariables
  >(MarkCheaperOfferAsSwitchedDocument, options)
}
export type MarkCheaperOfferAsSwitchedMutationHookResult = ReturnType<
  typeof useMarkCheaperOfferAsSwitchedMutation
>
export type MarkCheaperOfferAsSwitchedMutationResult =
  Apollo.MutationResult<MarkCheaperOfferAsSwitchedMutation>
export type MarkCheaperOfferAsSwitchedMutationOptions = Apollo.BaseMutationOptions<
  MarkCheaperOfferAsSwitchedMutation,
  MarkCheaperOfferAsSwitchedMutationVariables
>
export const SearchForCheaperOffersDocument = gql`
  mutation searchForCheaperOffers($bookingId: UUID!) {
    booking(id: $bookingId) {
      searchForCheaperOffers {
        id
      }
    }
  }
`
export type SearchForCheaperOffersMutationFn = Apollo.MutationFunction<
  SearchForCheaperOffersMutation,
  SearchForCheaperOffersMutationVariables
>

/**
 * __useSearchForCheaperOffersMutation__
 *
 * To run a mutation, you first call `useSearchForCheaperOffersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchForCheaperOffersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchForCheaperOffersMutation, { data, loading, error }] = useSearchForCheaperOffersMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useSearchForCheaperOffersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SearchForCheaperOffersMutation,
    SearchForCheaperOffersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SearchForCheaperOffersMutation,
    SearchForCheaperOffersMutationVariables
  >(SearchForCheaperOffersDocument, options)
}
export type SearchForCheaperOffersMutationHookResult = ReturnType<
  typeof useSearchForCheaperOffersMutation
>
export type SearchForCheaperOffersMutationResult =
  Apollo.MutationResult<SearchForCheaperOffersMutation>
export type SearchForCheaperOffersMutationOptions = Apollo.BaseMutationOptions<
  SearchForCheaperOffersMutation,
  SearchForCheaperOffersMutationVariables
>
export const CreateSplitTicketingBookingDocument = gql`
  mutation createSplitTicketingBooking {
    createSplitTicketingBooking {
      id
    }
  }
`
export type CreateSplitTicketingBookingMutationFn = Apollo.MutationFunction<
  CreateSplitTicketingBookingMutation,
  CreateSplitTicketingBookingMutationVariables
>

/**
 * __useCreateSplitTicketingBookingMutation__
 *
 * To run a mutation, you first call `useCreateSplitTicketingBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSplitTicketingBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSplitTicketingBookingMutation, { data, loading, error }] = useCreateSplitTicketingBookingMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSplitTicketingBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSplitTicketingBookingMutation,
    CreateSplitTicketingBookingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSplitTicketingBookingMutation,
    CreateSplitTicketingBookingMutationVariables
  >(CreateSplitTicketingBookingDocument, options)
}
export type CreateSplitTicketingBookingMutationHookResult = ReturnType<
  typeof useCreateSplitTicketingBookingMutation
>
export type CreateSplitTicketingBookingMutationResult =
  Apollo.MutationResult<CreateSplitTicketingBookingMutation>
export type CreateSplitTicketingBookingMutationOptions = Apollo.BaseMutationOptions<
  CreateSplitTicketingBookingMutation,
  CreateSplitTicketingBookingMutationVariables
>
